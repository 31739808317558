<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9847 1C10.342 1 8.79172 1.39611 7.4243 2.09804L11.1935 8.62647C11.6893 8.46228 12.2746 8.46274 12.7701 8.62767L16.5412 2.09601C15.1747 1.39535 13.6259 1 11.9847 1Z"
    />
    <path
      d="M14.4175 10.4423C14.3314 10.0647 14.1599 9.71967 13.9243 9.42858L15.8318 6.12464L17.7393 2.8207C20.1651 4.53052 21.7963 7.29218 21.9694 10.4423H14.4175Z"
    />
    <path
      d="M14.3346 11.8423C14.1922 12.2405 13.9517 12.592 13.6422 12.8677L17.4134 19.3996C19.9428 17.7615 21.6854 15.0119 21.9497 11.8423H14.3346Z"
    />
    <path
      d="M11.5909 13.4701C11.8458 13.51 12.1183 13.5098 12.3731 13.4695L16.1876 20.0765C15.7758 20.2676 15.3488 20.4314 14.9089 20.5657L12.3481 16.2076C12.1857 15.9311 11.8145 15.9311 11.6521 16.2076L9.08604 20.5734C8.6357 20.4373 8.19878 20.2702 7.77776 20.0747L11.5909 13.4701Z"
    />
    <path
      d="M10.3207 12.8702C10.0098 12.5941 9.76819 12.2417 9.62535 11.8423H2.01969C2.28391 15.0104 4.02493 17.7588 6.55231 19.3972L10.3207 12.8702Z"
    />
    <path
      d="M10.0382 9.42546C9.80131 9.71721 9.62884 10.0634 9.54247 10.4423H2C2.17307 7.29376 3.80267 4.53329 6.22644 2.82327L10.0382 9.42546Z"
    />
    <path
      d="M10.5899 11.0003C10.5899 10.2271 11.2167 9.60027 11.9899 9.60027C12.7631 9.60027 13.3899 10.2271 13.3899 11.0003C13.3899 11.7735 12.7631 12.4003 11.9899 12.4003C11.2167 12.4003 10.5899 11.7735 10.5899 11.0003Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.3256 22.0611C14.3304 22.0105 14.3191 21.9573 14.2877 21.9086L12.1826 18.6123C12.0873 18.4629 11.8694 18.4629 11.7741 18.6123L9.66835 21.9086C9.56532 22.0702 9.68138 22.2817 9.87264 22.2817H14.0835C14.2173 22.2817 14.3144 22.1784 14.3256 22.0611Z"
    />
  </svg>
</template>
